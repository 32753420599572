let from_yr = new Date().getFullYear() - 10;
let to_yr = new Date().getFullYear();
let yr_arr = []
for(let yr = to_yr; yr >= from_yr; yr--){
  yr_arr.push({value: yr, text: yr});
}

export const result_page_data = {
  year: yr_arr,
  exam: [
    {value: 'DIE', text: 'Diploma in Engineering'},
    {value:'DIMT', text: 'Diploma in Medical'},
    {value:'DIAFFL', text: 'Diploma in Textile/Agriculture/Fisheries/Forestry/Livestock/Tourism and Hospitality'},
  ],
  class: {
    dip_eight: [
      {value:'1st', text: 'First'},
      {value:'2nd', text: 'Second'},
      {value:'3rd', text: 'Third'},
      {value:'4th', text: 'Fourth'},
      {value:'5th', text: 'Fifth'},
      {value:'6th', text: 'Sixth'},
      {value:'7th', text: 'Seventh'},
      {value:'8th', text: 'Eighth'}
    ],
  }
}
