<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col class="primary" align-self="center" cols="12">
        <Logo/>
      </v-col>
      <v-col class="white" cols="12">
        <v-row align="center" justify="center">

          <v-col align-self="center" sm="8" md="6" lg="6" xl="6">
            <v-card outlined class="elevation-2 pa-4 my-5" min-height="350">
              <v-card-text v-if="!isRequest">
                <v-form ref="resultForm" @submit.prevent="handleSubmitClick()">
                  <v-row>
                    <v-col cols="3" class="py-0">
                      <v-subheader class="float-right pa-0">Examination</v-subheader>
                    </v-col>
                    <v-col cols="9" class="py-0">
                      <v-select
                        v-model="resultForm.exam"
                        :rules="[required('Examination')]"
                        :items="items.exam"
                        placeholder="Select One"
                        dense
                        solo
                      ></v-select>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="3" class="py-0">
                      <v-subheader class="float-right pa-0">Year</v-subheader>
                    </v-col>
                    <v-col cols="9" class="py-0">
                      <v-select
                        v-model="resultForm.year"
                        :rules="[required('Year')]"
                        :items="items.year"
                        placeholder="Select One"
                        dense
                        solo
                      ></v-select>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="3" class="py-0">
                      <v-subheader class="float-right pa-0">Semester</v-subheader>
                    </v-col>
                    <v-col cols="9" class="py-0">
                      <v-select
                        v-model="resultForm.class"
                        :items="items.class['dip_eight']"
                        placeholder="Select One"
                        dense
                        solo
                      ></v-select>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="3" class="py-0">
                      <v-subheader class="float-right pa-0">Roll</v-subheader>
                    </v-col>
                    <v-col cols="9" class="py-0">
                      <v-text-field
                        v-model="resultForm.roll"
                        :rules="[required('Roll')]"
                        dense placeholder="123.."
                        type="number"
                        solo
                        >
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <v-btn small type="submit" class="primary float-right ml-2">
                        Submit
                      </v-btn>
                      <v-btn small class="warning float-right" @click="reset">
                        Reset
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>

              <v-card-text v-else>
                <v-skeleton-loader v-if="isLoading"
                  type="list-item-three-line, card-heading, list-item-three-line, actions"
                ></v-skeleton-loader>
                <div v-else-if="isPolling">
                  <div class="text-center">
                    <v-progress-circular
                      :rotate="360"
                      :size="100"
                      :width="15"
                      :value="waiting"
                      color="primary"
                    >
                      {{ waiting }}
                    </v-progress-circular>
                  </div>
                  <pre>{{ Object.keys(result).length>0 ? result : '' }}</pre>
                  <div class="text-center" width="100">
                    <v-btn small class="warning mt-10 text-center" @click="cancelPolling">
                      Cancel
                    </v-btn>
                  </div>
                </div>
                <div v-else>
                  <pre>{{ result }}</pre>
                  <div class="text-center" width="100">
                    <v-btn small class="warning mt-10 text-center" @click="cancelPolling">
                      Search again
                    </v-btn>
                  </div>
                </div>
              </v-card-text>

            </v-card>
          </v-col>

        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import validations from "@/utils/validations";
import Logo from "@/layouts/components/Logo.vue"
import { result_page_data } from "@/utils/constant";
import { mapActions } from "vuex";
import { REQUEST_RESULT, POLLING_RESULT } from "@/store/_actiontypes";

export default {
  components: {
    Logo
  },

  data(){
    return {
      ...validations,
      isRequest: false,
      isLoading: false,
      isPolling: false,
      waiting: 0,
      counterStep: 5,
      uuid: null,
      items: result_page_data,
      resultForm: {
        exam: "DIE",
        year: null,
        class: null,
        roll: null
      },
      result: {}
    };
  },

  created(){
    this.$vuetify.theme.dark = this.$tools.getLocal('settings').theme.int;
  },

  methods: {
    ...mapActions("result", [REQUEST_RESULT, POLLING_RESULT]),

    handleSubmitClick(){
      this.result = {}
      this.uuid = null
      
      if(!this.$refs.resultForm.validate()) return;
      this.isRequest = true;
      this.isLoading = true;

      this.getuuid();
      if(this.uuid != null){
        this.resultForm["uuid"] = this.uuid;
      }
      let data = this.resultForm;

      this.REQUEST_RESULT(data).then((resp) => {
        this.isLoading = false;
        this.isPolling = true;
        if(resp.uuid != null){
          this.uuid = resp.uuid
          this.setuuid();
          this.polling();
        }
      }).catch((err) => {
        this.isRequest = false;
        this.isLoading = false;
        this.isPolling = false;
      });
    },
    polling(){
      if(this.isPolling && this.isRequest){
        try{
          this.POLLING_RESULT({ uuid: this.uuid }).then(resp => {
            if(resp.status == "SUCCESS"){
              this.isPolling = false
              this.result = resp
              return true
            }
          }, error => {
            this.isPolling = false
          });
        } catch(error){}
      }

      setTimeout(() => {
        if(this.waiting === 100){
          this.waiting = 0
        }
        this.waiting += this.counterStep;

        this.polling(this.uuid);
      }, 3000);
    },

    setuuid(){
      if(this.resultForm.hasOwnProperty('uuid')){
        delete this.resultForm['uuid']
      }
      var hash = this.$tools.hash(JSON.stringify(this.resultForm))
      var data = {"uuid": this.uuid}
      this.$tools.setLocal(`result_${hash}`, data, 1);
    },
    getuuid(){
      if(this.resultForm.hasOwnProperty('uuid')){
        delete this.resultForm['uuid']
      }

      var hash = this.$tools.hash(JSON.stringify(this.resultForm))
      var uuid_obj = this.$tools.getLocal(`result_${hash}`)
      if(uuid_obj && uuid_obj.uuid != ""){
        this.uuid = uuid_obj.uuid;
      }
    },

    reset(){
      this.cancelPolling()
      this.$refs.resultForm.reset();
    },
    cancelPolling() {
      this.waiting = 0;
      this.isRequest = false;
      this.isLoading = false;
      this.isPolling = false;
    }
  },
};
</script>