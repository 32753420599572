let required = (property) => {
  return (v) => !!v || `${property} is required`;
};

let email = () => {
  return (v) =>
    (v && /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v)) ||
    "Must be a valid email";
};

let validateEmail = (email) => {
  return (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) ? true : false;
}

let intent = () => {
  return (v) => (v && /^[A-Za-z_]+$/.test(v)) || "Must be a valid Intent Name";
};

let minValue = (property, minValue) => {
  return (v) =>
    (v && parseFloat(v) >= minValue) ||
    `${property} must be greater than ${minValue}`;
};

let maxValue = (property, maxValue) => {
  return (v) =>
    (v && parseFloat(v) <= maxValue) ||
    `${property} must be less than ${maxValue}`;
};

let minLength = (property, minLength) => {
  return (v) =>
    (v && v.length >= minLength) ||
    `${property} must be greater than ${minLength} characters`;
};

let maxLength = (property, maxLength) => {
  return (v) =>
    (v && v.length <= maxLength) ||
    `${property} must be less than ${maxLength} characters`;
};

let match = (property) => {
  return (v) => 
    (v === property) ||
    "The password confirmation does not match.";
};

let isNumeric = (property) => {
  return (v) => 
    (v && isNaN(v)) ||
    `${property} can not only numeric`;
};

let password = (property) => {
  return (v) => v && passwordCriteria(v) || `Your ${property} must contain at least one uppercase, one lowercase, one special character or one digit`;
};

let passwordWithEmail = (property, fullEmail) => {
  return (v) => v && fullEmail && passwordEmailCheck(v, fullEmail) || `${property} can not contain email`;
};

let passwordWithName = (property, fullName, otherProperty) => {
  return (v) => v && fullName && passwordNameCheck(v, fullName) || `${property} can not contain ${otherProperty}`;
}

// checking password length and password criteria
const passwordCriteria = pw => {
  let criteria = 0;
    if (pw.toUpperCase() != pw) {
        // has lower case letters
        criteria++;
    }
    if (pw.toLowerCase() != pw) {
        // has upper case letters
        criteria++;
    }
    if (/^[a-zA-Z0-9]*$/.test(pw) === false) {
        // has special characters
        criteria++;
    }
    if (/\d/.test(pw) === true) {
        // has numbers
        criteria++;
    }
    // returns true if 3 or more criteria was met and length is appropriate
    return (criteria >= 3 && pw.length >= 8);
}

// email username cheking with password -> is email username includes in user password
const passwordEmailCheck = (password, email) => {
  let emailUserName = email.split('@')[0];
  let emailOrganizationName = email.split('@')[1].split('.')[0];
  let fullEmail = emailUserName + emailOrganizationName;
  let checkEmail = fullEmail
    .match(/.{3}/g)
    .concat(fullEmail.substr(1).match(/.{3}/g), fullEmail.substr(2).match(/.{3}/g));
  let isCheckEmail = new RegExp(checkEmail.join("|"), "i").test(password);
  return !isCheckEmail;
};

// user Name and Display name is checking with password -> is user name and display name include in password?
const passwordNameCheck = (password, name) => {
  let newName = name;
  while(newName.length < 5){
  	newName = newName.repeat(2)
  }
  let checkName = newName
    .match(/.{3}/g)
    .concat(newName.substr(1).match(/.{3}/g), newName.substr(2).match(/.{3}/g));
  let isCheckName = new RegExp(checkName.join("|"), "i").test(password);
  return !isCheckName;
};

export default {
  required,
  email,
  validateEmail,
  minValue,
  maxValue,
  minLength,
  maxLength,
  intent,
  match,
  password,
  passwordWithEmail,
  passwordWithName,
  isNumeric,
};
