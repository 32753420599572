<template>
  <div class="mb-1">
    <img src="@/assets/images/logos/bd_logo.png" class="logo" />
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
  .logo {
    max-height: 65px;
  }
</style>
